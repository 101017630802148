export class MQTTPacket {
  constructor(packet) {
      this.topic = packet.topic || "";
      this.rawPayload = packet.payload || "";
      this.capabilities = [];
      this.serial = '';

      this.parsePayload(this.rawPayload);
  }

  /**
   * Parses the payload.
   * This is a naive parser and assumes the payload is a JSON string.
   */
  parsePayload(rawPayload) {
    // v1/ticadia/things/esp32-1/data/json
    this.serial = this.topic.split('/')[3];
    if (!this.topic.includes('data/json')) {
      // save individual readings (temp,k=22)
      // parse payload
      try {
        let channel;
        let payload;

        if (this.topic !== '') {
          channel = this.topic.split('/')[5];
        }

        if (rawPayload.toString() !== '') {
          payload = rawPayload.toString();
        }

        if (!channel || !payload) {
          return;
        }

        const parts = payload.split(',');
        // payload can be of two forms: temp,k=22 or 22
        let uplink = {};
        if (parts.length !== 2) {
          uplink = {
            type: 'virtual',
            unit: '',
            value: payload,
            channel: channel,
          }
        } else {
          const vals = parts[1].split('=');
          uplink = {
            type: parts[0],
            unit: vals[0],
            value: vals[1],
            channel: channel,
          }
        }
        this.capabilities.push(uplink);
      } catch (error) {
        console.log(error);
      }
    } else {
      try {
        const jsonPayload = JSON.parse(rawPayload);
        if (jsonPayload.source === "http" && jsonPayload.payload !== undefined) {
          this.capabilities = jsonPayload.payload;
        } else {
          this.capabilities = jsonPayload;
        }
      } catch (err) {
          console.error("Failed to parse payload:", rawPayload);
          return null;
      }      
    }      
  }

  /**
   * Get the topic of the MQTT packet.
   */
  getTopic() {
      return this.topic;
  }

  getSerial() {
      return this.serial;
  }

  /**
   * Get raw payload.
   */
  getRawPayload() {
      return this.rawPayload;
  }

  /**
   * Get capabilities
   */
  getCaps() {
      return this.capabilities;
  }
}
