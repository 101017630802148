import { MapContainer, TileLayer, Marker, Popup } from "react-leaflet";

export default function Map({ data, capability}) {
  const position = [51.505, -0.09];
  if (typeof capability.value === 'object') {
    console.log('GPS', capability.value);
    position[0] = capability.value[0];
    position[1] = capability.value[1];
  } else if (capability.array_value) {
    position[0] = parseFloat(capability.array_value[0]);
    position[1] = parseFloat(capability.array_value[1]);
  }

  return (
    <MapContainer center={position} zoom={13} scrollWheelZoom={false} style={{ height: '90%', width: '100%' }} className="mt-2">
  <TileLayer
    attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
    url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
  />
  <Marker position={position}>
    <Popup>
      Coordinates: {position[0]}, {position[1]}
    </Popup>
  </Marker>
</MapContainer>
  );
}