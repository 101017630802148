import React, { useState, useEffect } from 'react';
import { Button, Text, Flex, Badge, Callout } from '@tremor/react';
import * as sourceAPI from '../../../services/sources';
import { JSONTree } from 'react-json-tree';
import {theme} from '../../../components/JSONThreeThree';


const LoRaOnboarding = ({ profile }) => {
  const [source, setSource] = React.useState('');
  const [step, setStep] = useState(1);

  const getSource = async () => {
    const s = await sourceAPI.findOne(profile.source);
    setSource(s);
    console.log(s);
  }

  useEffect(() => {
    console.log(profile);
    getSource();
  }, [profile.source]);


  console.log(source);

  const steps = [
    {
      title: 'Complete Source Configuration',
      description: 'Make sure to configure your LoRa Network Server to send data to Zafron.',
      content: (
        <div className='px-1'>
          <Text className="mb-4">To verify if packets are reaching Zafron, navigate to your <a href="/sources" className='text-blue-500'>Sources</a> and check logs. </Text>
          {source && source.logs && source.logs.length > 0 && (
            <Callout title="Packet Received">We have received a packet from the configured source. Proceed to the next step.</Callout>
          )}
          
            {source && source.logs && source.logs.length > 0 && (
              <div className='mt-2 border border-gray p-4 rounded'>
                <JSONTree 
              theme={{
                extend: theme,
              }}
              data={JSON.parse(source.logs[0].message)} 
              invertTheme={false}
              hideRoot={true} /></div>
            )}
        </div>
      ),
    },
    {
      title: 'Complete Device Profile',
      description: 'Complete the device profile configuration.',
      content: (
        <div className='px-1'>
          <Text className="mb-4">The Profile is used to decode and normalize the data for storage and visualization. </Text>
          <Text className="mb-4">By default Cayenne Low Power Payload (LPP) is selected. However, you are welcome to create your own decoder.</Text>
        </div>
      ),
    },
    {
      title: 'Start Monitoring',
      description: 'Your device is ready',
      content: (
        <div>          
          <Callout title="Note" className="mt-4">As soon as your network server starts sending packets and the device data is correctly decoded, this dashboard will display your sensor readings.</Callout>
      </div>
      ),
    },
  ];

  const nextStep = () => {
    if (step < steps.length) {
      setStep(step + 1);
    }
  };

  const prevStep = () => {
    if (step > 1) {
      setStep(step - 1);
    }
  };

  return (
    <div className="p-6">
      <h1 className="text-xl font-semibold mb-4">Getting Started with LoRaWAN</h1>
      <Text className="mb-6">Follow the steps to set up your device. This allows you to start monitoring your LoRa sensor.</Text>
    
      {steps.map((s, index) => (
        <div
          key={index}
          className={`p-4 rounded-tremor-default border border-tremor-border bg-tremor-background ${index + 1 === step ? 'bg-blue-50' : 'border-gray-200 bg-white'} rounded-lg mb-4`}
        >
          <Flex className="items-center justify-between">
            <div>
              <h3 className="font-medium text-tremor-content-strong">{index + 1}. {s.title}</h3>
              {index + 1 === step && (
                <Text className="text-gray-600 mt-2">{s.description}</Text>
              )}
            </div>
            {index + 1 < step && <Badge color="green">Completed</Badge>}
          </Flex>
          {index + 1 === step && (
            <div className="mt-4">
              {s.content}
            </div>
          )}
        </div>
      ))}

      <Flex justifyContent="end" alignItems='center' className="mt-6 space-x-4">
        {step > 1 && (
          <Button variant="light" onClick={prevStep}>
            Back
          </Button>
        )}
        {step < steps.length && (
          <Button onClick={nextStep}>
            Continue
          </Button>
        )}
      </Flex>
    </div>
  );
};

export default LoRaOnboarding;