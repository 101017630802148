export const SOURCE_LIST = [
  { id: 1, name: 'ChirpStack V3', value: 'chirpstackv3' },
  { id: 2, name: 'ChirpStack V4 - JSON Format', value: 'chirpstackv4' },
  { id: 3, name: 'TheThingsNetwork V3', value: 'ttnv3' },
  { id: 6, name: 'Helium - JSON Format', value: 'helium' },
];

export const getEndpoint = (source) => {
  if (!source.maskId || !source.apiKey) {
    return '';
  }

  const baseUrl = process.env.REACT_APP_HOSTNAME || 'app.zafron.dev';
  return `https://${baseUrl}/api/ingress/${source.maskId}?apiKey=${source.apiKey}`;
}