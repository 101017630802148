export const theme = {
  scheme: "light",
  base00: "#ffffff",   // Background color (white)
  base01: "#e5e9f2",   // Lighter background for levels
  base02: "#e5e9f2",   // Borders or lighter background
  base03: "#d1d5db",   // Even lighter grays for separation
  base04: "#999999",   // Muted gray for non-important text (comments)
  base05: "#333333",   // Main text color (dark gray, close to black)
  base06: "#666666",   // Slightly lighter gray
  base07: "#000000",   // Pure black for maximum contrast elements
  base08: "#1d4ed8",   // Soft blue for keys or important identifiers
  base09: "#e67e22",   // Muted orange for numbers, constants
  base0A: "#f4c542",   // Soft yellow for strings
  base0B: "#2ecc71",   // Soft green for function names, or success messages
  base0C: "#1abc9c",   // Soft cyan for other syntax highlights
  base0D: "#1d4ed8",   // Muted purple for object names
  base0E: "#e74c3c",   // Soft red for errors
  base0F: "#718096",   // Muted gray for less important information
};