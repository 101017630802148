import {
  Table,
  TableHead,
  TableRow,
  TableHeaderCell,
  TableBody,
  TableCell,
  Badge,
  Card,
} from '@tremor/react';

import { toFriendlyDate } from '../../../services/utils';

function NoData() {
  return (
    <div className='mt-5 h-full w-full' height="112">
       <div className='tremor-Flex-root flex flex-row justify-center items-center w-full h-full border border-dashed rounded-tremor-default border-tremor-border dark:border-tdark-remor-border'>
        <div className='text-tremor-default text-tremor-content dark:text-dark-tremor-content p-10'>No data</div>
      </div>
    </div>
   
  )
}

const DataPill = ({ type, value, unit }) => (
  <Card
    className="p-4 hover:bg-tremor-background-muted text-sm  hover:dark:bg-dark-tremor-background-muted"
  >
    <p className="text-tremor-default text-tremor-content dark:text-dark-tremor-content">
      <span className="absolute inset-0" aria-hidden={true} />
      {type}
    </p>
    <p className="mt-3 flex items-end">
      <span className="text-tremor-metric font-semibold text-tremor-content-strong dark:text-dark-tremor-content-strong">
        {value}
      </span>
      <span className="font-semibold text-tremor-content-subtle dark:text-dark-tremor-content-subtle">
        {unit}
      </span>
    </p>
  </Card>
);

export const BadgeExample = ({ value, name, unit}) => (
  <div className="flex items-center justify-between gap-8 rounded-md bg-blue-50 py-2.5 pl-2.5 pr-4 text-sm dark:bg-blue-900/50">
    <div className="flex items-center gap-2 truncate">
      <Badge className="ring-none dark:ring-none rounded-full bg-blue-800 text-white dark:bg-blue-500 dark:text-white">
        {name}
      </Badge>
      <span className="truncate text-blue-800 dark:text-blue-400">
        {unit}
        <span className="font-semibold">263 transactions</span>
      </span>
    </div>
    <button className="font-semibold text-blue-800 dark:text-blue-400">
      {value}
    </button>
  </div>
);


export default function LogTable({ logs }) {
  return (
    (logs.readings && logs.readings.length === 0) ? <NoData /> : ( <>
    <Table className="mt-6">
      <TableHead className='text-black'>
        <TableRow className='border-b border-tremor-border text-black'>
          <TableHeaderCell>Time</TableHeaderCell>
          <TableHeaderCell>Channel</TableHeaderCell>
          <TableHeaderCell>Type</TableHeaderCell>
          <TableHeaderCell>Unit</TableHeaderCell>
          <TableHeaderCell>Value</TableHeaderCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {logs.readings.map((log) => (
          <TableRow key={`${log.timestamp}-${log.metadata.channel}-${log.value}`}>
            <TableCell>
              {toFriendlyDate(log.timestamp)}
            </TableCell>
            <TableCell>
            {log.metadata.channel}
            </TableCell>
            <TableCell>{log.metadata.type}</TableCell>
            <TableCell>{log.metadata.unit}</TableCell>
            <TableCell>
              {(log.metadata.type === 'gps') ? (
                <Badge size="xs">{`${log.array_value[0]}, ${log.array_value[1]}`}</Badge>
              ) : (
              <Badge size="xs">{log.value}</Badge>)}
              {/* <DataPill value={log.value} type={log.metadata.type} unit={log.metadata.unit} /> */}
            </TableCell>
          </TableRow>
        ))}
      </TableBody>
    </Table>
  </>  
    )
  );
}
