import { Dialog } from "@headlessui/react";
import { Fragment } from "react";
import { Transition } from "@headlessui/react";
import { getEndpoint } from "../pages/sources/utils";
import { Button, Card } from "@tremor/react";

function Drawer({ isOpen, onClose, rowData }) {
  return (
    <Transition.Root show={isOpen} as={Fragment}>
      <Dialog as="div" className="fixed inset-0 overflow-hidden" onClose={onClose}>
        <div className="absolute inset-0 overflow-hidden">
          <Dialog.Overlay className="absolute inset-0 bg-black bg-opacity-30" />

          <div className="fixed inset-y-0 right-0 flex max-w-full pl-10">
            <Transition.Child
              as={Fragment}
              enter="transform transition ease-in-out duration-500 sm:duration-700"
              enterFrom="translate-x-full"
              enterTo="translate-x-0"
              leave="transform transition ease-in-out duration-500 sm:duration-700"
              leaveFrom="translate-x-0"
              leaveTo="translate-x-full"
            >
              <div className="relative w-screen max-w-md">
                <div className="flex flex-col h-full py-6 bg-white shadow-xl">
                  <div className="px-4 sm:px-6 border border-b">
                    <h2 className="text-lg font-medium text-gray-900">
                     {rowData ? rowData.name : "User"}
                    </h2>
                  </div>
                  <div className="relative flex-1 px-4 sm:px-6">
                    {/* Content of the drawer */}
                    <h4 className="mt-8 font-semibold text-tremor-content-strong dark:text-dark-tremor-content-strong">
                      Provider:
                    </h4>
                    <p>{rowData ? rowData.provider : "No data"}</p>
                    <h4 className="mt-8 font-semibold text-tremor-content-strong dark:text-dark-tremor-content-strong">
                      Endpoint: 
                    </h4>
                    {/* text input */}
                    <div className="relative">
                      <textarea
                        className="w-full p-2 border border-gray-300 rounded-md bg-gray-100 focus:outline-none focus:ring-2 focus:ring-blue-500"
                        value={getEndpoint(rowData)} // Use the value to display the endpoint
                        readOnly // Makes the textarea non-editable
                        onClick={(e) => e.target.select()} // Select all text when clicked
                      />
                      <button
                        className="absolute bottom-2 right-2 px-2 py-1 text-sm text-white bg-blue-600 rounded-md hover:bg-blue-700 focus:outline-none"
                        onClick={() => {
                          navigator.clipboard.writeText(getEndpoint(rowData)); // Copy to clipboard
                        }}
                      >
                        Copy
                      </button>
                    </div>
                    <Card
                        className="p-4 mt-2 hover:bg-tremor-background-muted hover:dark:bg-dark-tremor-background-muted"
                      >
                        <p className="text-tremor-default text-tremor-content dark:text-dark-tremor-content">
                          Event Count
                        </p>
                        <p className="mt-3 flex items-end">
                          <span className="text-tremor-metric font-semibold text-tremor-content-strong dark:text-dark-tremor-content-strong">
                            {rowData.eventCount+' '}
                          </span>
                          <span className="font-semibold text-tremor-content-subtle dark:text-dark-tremor-content-subtle">
                            
                          </span>
                        </p>
                      </Card>

                    <h4 className="mt-8 font-semibold text-tremor-content-strong dark:text-dark-tremor-content-strong">
                      Logs:
                    </h4>
                    <div className="relative">
                      <pre className="w-full h-96 p-4 bg-gray-100 border border-gray-300 rounded-md text-sm font-mono overflow-auto">
                        {JSON.stringify(rowData.logs, null, 2)} {/* Format JSON with indentation */}
                      </pre>
                    </div>
                    
                  </div>
                  <div className="px-4 py-4 sm:px-6">
                    <Button onClick={onClose} >
                      Okay
                    </Button>
                  </div>
                </div>
              </div>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
}

export default Drawer;