const { request } = require('./request');

const baseURL = "/api/devices";

export const fetchDevices = () => {
    return request(baseURL);
};

export const addDevice = (device) => {
    return request(baseURL, {
        method: 'POST',
        json: device
    });
};

export const removeDevice = (deviceId) => {
    return request(`${baseURL}/${deviceId}`, {
        method: 'DELETE'
    });
};

export const patchCapabilities = (deviceId, layout) => {
  return request(`${baseURL}/${deviceId}/capabilities?action=layout`, {
      method: 'PATCH',
      json: { layout }
  });
};

export const removeCapability = (deviceId, capability) => {
  return request(`${baseURL}/${deviceId}/capabilities?action=remove`, {
      method: 'PATCH',
      json: { capability: capability }
  });
};

export const getCapabilities = (deviceId) => {
  return request(`${baseURL}/${deviceId}/capabilities`);
}

export const patchDevice = (deviceId, payload) => {
  return request(`${baseURL}/${deviceId}`, {
      method: 'PATCH',
      json: payload
  });
};

export const fetchOne = (id) => {
  return request(`${baseURL}/${id}`);
};

export const fetchReadings = (id, page, limit, startDate, endDate, filters) => {
  // Construct the base URL with mandatory parameters
  let url = new URL(`https://${baseURL}/${id}/measurements`);
  url.searchParams.append("page", page);
  url.searchParams.append("limit", limit);

  // Append filters to the URL if they exist
  Object.entries(filters).forEach(([key, value]) => {
    if (value.length) {
      url.searchParams.append(key, value.join(','));
    }
  });

  // Append date range if both dates are provided
  if (startDate && endDate) {
    url.searchParams.append("startDate", startDate);
    url.searchParams.append("endDate", endDate);
  }

  const p = "/api" + url.pathname + url.search;
  // Make the request with the constructed URL
  return request(p);
}

export const fetchHistogram = (id) => {
  return request(`${baseURL}/${id}/histogram`);
}

export const fetchLatest = (id) => {
  return request(`${baseURL}/${id}/latest`);
}

export const fetchMetric = (id, channel, options) => {
  return request(`${baseURL}/${id}/measurements/${channel}?${options}`);
}

export const getDeviceUsage = (id) => {
  return request(`${baseURL}/${id}/usage`);
}

