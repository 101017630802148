import { CheckIcon } from "@heroicons/react/24/outline";
import { List, ListItem, Icon } from '@tremor/react';

function classNames(...classes) {
  return classes.filter(Boolean).join(' ');
}

const plans = [
  {
    name: 'Free plan',
    price: '$0',
    badgeText: 'current',
    description: 'Free for ever with limited features',
    features: [
      '3 devices',  
      'Up to 1,000 uplinks per month¹',
      '24 hours data retention',
      '5 rules',
      '20 email notifications',
    ],
    footnote:
      '¹ Uplinks are individual data points sent from your devices to Zafron. Max of 120 uplinks per hour.',
    isUpgrade: false,
    buttonText: false,
    buttonLink: false,
  },
  {
    name: 'Premium',
    price: '$7',
    badgeText: 'add-on',
    description: 'Unlock the full potential of your project',
    features: [
      '15 devices',
      'Up to 90,000 uplinks per month¹',
      '90 days of data retention',
      'Unlimited rules and email notifications',
      '25 SMS Notifications',
      'Device Integration Support',
      '24/7 support',
      'Export data',
      'API access',
    ],
    footnote: '¹ Uplinks are throttled per hour',
    isUpgrade: true,
    buttonText: 'Upgrade',
    buttonLink: '/api/checkout/create',
  },
];

export default function PricingPlans({ userId, userEmail }) {
  return (
    <>
      <div className="grid grid-cols-1 gap-6 md:grid-cols-2 lg:grid-cols-2">
        {/* bg-gray-950 used as color to match underlying dark mode background color */}
        {plans.map((plan, planIdx) => (
          <div
            key={planIdx}
            className={classNames(
              plan.isUpgrade
                ? 'bg-tremor-background-muted'
                : 'bg-tremor-background',
              'rounded-tremor-default border border-tremor-border p-6',
            )}
          >
            <div className="flex items-center justify-between">
              <div className="flex items-center space-x-2">
                <h4 className="text-tremor-default font-semibold leading-8 text-tremor-content-strong ">
                  {plan.name}
                </h4>
                <span
                  className={classNames(
                    plan.isUpgrade
                      ? 'bg-tremor-brand-faint text-tremor-brand-emphasis ring-1 ring-inset ring-tremor-brand-muted'
                      : 'bg-tremor-background-muted text-tremor-content-emphasis ring-1 ring-inset ring-tremor-ring',
                    'inline-flex items-center rounded-tremor-small px-2 py-1 text-tremor-label font-medium',
                  )}
                >
                  {plan.badgeText}
                </span>
              </div>
              {plan.isUpgrade ? (
                <p className="leading-5">
                  <span className="text-tremor-title font-semibold text-tremor-content-strong ">
                    {plan.price}
                  </span>
                  <span className="text-tremor-default text-tremor-content ">
                    /mo
                  </span>
                </p>
              ) : null}
            </div>
            <p className="mt-3 text-tremor-default text-tremor-content ">
              {plan.description}
            </p>
            <List className="mt-4 text-tremor-content-emphasis -emphasis">
              {plan.features.map((feature) => (
                <ListItem
                  key={feature}
                  className="justify-start space-x-2 py-2.5"
                >
                  <Icon icon={CheckIcon} size="sm"
                    className={classNames(
                      plan.isUpgrade
                        ? 'text-tremor-brand'
                        : 'text-tremor-content-subtle',
                      'size-1 shrink-0',
                    )}
                    aria-hidden={true}
                  />
                  <span>{feature}</span>
                </ListItem>
              ))}
            </List>
            <p className="mt-4 text-tremor-label text-tremor-content ">
              {plan.footnote}
            </p>
            {plan.isUpgrade ? (
              <form action={plan.buttonLink} method="POST">
                <input type="hidden" name="userId" value={userId} />
                <input type="hidden" name="userEmail" value={userEmail} />
              <button type="submit"
                className="mt-4 block w-full whitespace-nowrap rounded-tremor-small bg-tremor-brand py-2.5 text-center text-tremor-default font-medium text-tremor-brand-inverted shadow-tremor-input hover:bg-tremor-brand-emphasis dark:bg-dark-tremor-brand dark:text-dark-tremor-brand-inverted dark:shadow-dark-tremor-input dark:hover:bg-dark-tremor-brand-emphasis"
              >
                {plan.buttonText}
              </button>
              </form>
            ) : null}
          </div>
        ))}
      </div>
    </>
  );
}